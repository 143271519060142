import React, { Component } from "react";
import Axios from "axios";
import Cookies from "universal-cookie";

class Photon extends Component {
  static post = (ip, port, endpoint, obj) => {
    const tokenId = localStorage.getItem("tokenId");
    return new Promise((resolve, reject) => {
      try {
        console.log("got inside photo.post fnx");
        let url = ip + port + endpoint;
        console.log("sending post request to = " + url);

        let res = Axios.post(url, obj, {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        });
        resolve(res);
      } catch (error) {
        console.log("error = " + error);
        reject(error);
      }
    });
  };

  static get = (ip, port, endpoint, obj) => {
    const tokenId = localStorage.getItem("tokenId");
    return new Promise((resolve, reject) => {
      try {
        const cookies = new Cookies();
        console.log("got inside photo.get fnx", cookies.get("tokenId"));
        let url = ip + port + endpoint;
        console.log("sending get request to = " + url);

        let res = Axios.get(url, {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
          ...obj,
        });
        resolve(res);
      } catch (error) {
        console.log("error = " + error);
        reject(error);
      }
    });
  };
}

export default Photon;
