import React, { useState, useEffect } from "react";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import base64 from "react-native-base64";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

// import "antd/dist/antd.css";

import CodeBlock from "./CodeBlock";

const { TabPane } = Tabs;

function QuestionBox(props) {
  const [questionName, setQuestionName] = useState();
  const [showSolution, setShowSolution] = useState({});
  const [questionId, setQuestionId] = useState();
  const { ip, port } = Constants;

  const navigate = useNavigate();

  function isBase64(str) {
    // Basic regex for Base64 validation
    const base64Regex =
      /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

    // Check if the input is a valid Base64 string
    return base64Regex.test(str);
  }

  const fetchQuestionFnx = async (question_id, endpoint) => {
    let decodedQuestion;
    let decodedSolution;

    let res = await Photon.post(ip, port.hive, endpoint, { id: question_id });

    if ("question" in res.data) {
      if (!isBase64(res.data.question)) {
        props.setQuestionData((obj) => ({ ...obj, detail: res.data.question }));
      } else {
        decodedQuestion = base64.decode(res.data.question);
        props.setQuestionData((obj) => ({
          ...obj,
          detail: decodedQuestion,
          name: res.data.name,
          base64Question: res.data.question,
        }));
      }
    } else {
      // Move to dashboard
      console.log("got inside else");
      //navigate("/dashboard");
    }

    if ("solution" in res.data) {
      if (isBase64(res.data.solution)) {
        decodedSolution = base64.decode(res.data.solution);
        setShowSolution(decodedSolution);
      } else {
        setShowSolution(res.data.solution);
      }
    }
  };

  useEffect(() => {
    console.log("This is id question == " + JSON.stringify(props.questionData));
    if (
      props &&
      props.questionData &&
      // check if questionData is not empty
      Object.keys(props.questionData).length !== 0 &&
      // check if question id is not same as previous question id
      props.questionData.id &&
      questionId !== props.questionData.id
    ) {
      fetchQuestionFnx(props.questionData.id, "/get_question_by_id");
      fetchQuestionFnx(props.questionData.id, "/get_solution_by_id");
      setQuestionId(props.questionData.id);
      setQuestionName(props.questionData.name);
    }
  }, [props]);

  const callback = (key) => {
    console.log(key);
    console.log(showSolution);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        width: "23%",
        maxHeight: "100vh",
        overflowY: "auto !important",
      }}
    >
      <Typography variant="h5">{props.questionData?.name || ""}</Typography>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Question" key="1">
          <ReactMarkdown
            children={props.questionData?.detail || ""}
            remarkPlugins={[remarkGfm]}
            renderers={{
              code: CodeBlock,
            }}
          />
        </TabPane>
        <TabPane tab="Solution" key="2">
          <ReactMarkdown
            children={showSolution}
            remarkPlugins={[remarkGfm]}
            renderers={{
              code: CodeBlock,
            }}
          />
        </TabPane>
      </Tabs>
    </Paper>
  );
}

export default QuestionBox;
