import React from "react";

import "../../css/style.css";

const PageNotFound = () => {
  return (
    <div className="pnf-container">
      <p className="pnf-text">Page Not Found</p>
      <a href="/" className="pnf-link">
        Go to Home Page
      </a>
    </div>
  );
};

export default PageNotFound;
