import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import "../../css/markdown.css";

const MarkdownAnswer = ({ text, user }) => {
  return (
    <div
      style={{
        textAlign: user === "User" ? "right" : "left", // Conditional alignment

        margin: "10px 0",
      }}
    >
      <div
        style={{
          display: "inline-block",
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: user === "User" ? "#d1ecf1" : "#e2e3e5",
          color: user === "User" ? "#0c5460" : "#383d41",
          wordWrap: "break-word",
          overflowWrap: "break-word",
          maxWidth: user === "User" ? "80%" : "100%",
        }}
      >
        <ReactMarkdown
          children={text}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeHighlight]}
          className="markdown"
        />
      </div>
    </div>
  );
};

export default MarkdownAnswer;
