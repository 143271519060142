import React from "react";
import Header from "../header";
import { Box, Stack, Paper, Avatar, Typography } from "@mui/material";
import { useSelector } from "react-redux";

function ProfilePage(props) {
  const authState = useSelector((state) => state.auth);

  return (
    <Box>
      <Header history={props.history} />
      <Stack direction="row" justifyContent="center" margin={20} border={0}>
        <Paper elevation={3} sx={{ width: 700, height: 300 }}>
          <Stack direction="row" justifyContent="space-between" margin={10}>
            <Stack justifyContent="center">
              <Avatar
                alt={authState.userDetails?.name}
                src={authState.userDetails?.picture || ""}
                sx={{ width: 150, height: 150 }}
              />
            </Stack>
            <Stack justifyContent="center">
              <Typography variant="h3">
                {authState.userDetails?.name || ""}
              </Typography>
              <Typography variant="h5">
                {authState.userDetails?.email || ""}
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
}

export default ProfilePage;
