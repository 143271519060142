import Photon from "../components/Photon/photon";
import { ip, port } from "../components/Config/constants";

export const googleSignIn = async (payload) => {
  const result = await verifyGoogleToken(
    payload.token_id,
    payload.referral_code
  );
  const tokenId = result.data?.token;
  if (tokenId) {
    localStorage.setItem("tokenId", tokenId);
  }
  return result;
};

export const verifyGoogleToken = async (tokenId, referralCode) => {
  let endpoint = "/auth_google";
  let obj = {
    token_id: tokenId,
    referral_code: referralCode,
  };

  let res = await Photon.post(ip, port.hive, endpoint, obj);
  return res;
};

export const validateAuthentication = async () => {
  // get token from local storage
  const tokenId = localStorage.getItem("tokenId");
  if (!tokenId) return false;
  try {
    let result = await verifyGoogleToken(tokenId);
    return result;
  } catch (error) {
    console.log("error inside validate_authentication");
    return false;
  }
};

// server_google_authetication = async (tokenId) => {
//   if (!tokenId) {
//     this.authenticating = true;
//     console.log("inside token not found");

//     let result = await this.validate_authentication();

//     this.authenticating = false;
//     return result;
//   }
//   // let result = await this.verify_google_token(tokenId);
//   // if (result.data.tokenIsVerified) {
//   //   console.log("google verified from props");
//   //   this.store_token_inside_cookie();
//   // } else {
//   //   //send user to login
//   //   //this.setState({ Navigate: "/" });
//   // }
//   // console.log(result);
// };

export const validateJWTToken = async () => {
  try {
    let endpoint = "/jwt_auth";
    const tokenId = localStorage.getItem("tokenId");
    if (!tokenId) {
      // logout user
      return false;
    }

    let obj = { token: tokenId };
    let res = await Photon.post(ip, port.hive, endpoint, obj);

    return res.data?.userDetails || false;
  } catch (error) {
    localStorage.removeItem("tokenId");
    return false;
  }
};
