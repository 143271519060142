import React, { Component } from "react";
import Cookies from "universal-cookie";
import Header from "../header";

import "../../css/style.css";

const cookies = new Cookies();
export class AdminPanelPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tokenId: null,
      question_data: {},
      edit_qid: '0',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  async componentDidMount() {
    console.log("component mounted : AdminPanelPage", this.props);
    //check if admin otherwise push to dashboard page
    /*let ip = Constants.ip;
    let port = Constants.port.hive;
    let endpoint = "/get_question_by_id";
    let obj = { id: question_id };

    let res = await Photon.post(ip, port, endpoint, obj);*/

    
    
  }

  add_new_question_page = ()  =>{
    this.props.history.push("/admin/0");
  }

  handleSubmit(event) {
    console.log('got inside admin edit question');
    this.props.history.push("/admin/" + this.state.edit_qid);
  }

  handleChange(event) {
    this.setState({edit_qid: event.target.value});
  }

  render() {
    return (
      <div>
        <Header history={this.props.history} />
        <div className="flexbox-container">
          <p> admin panel dashboard</p>
          <div><a onClick={this.add_new_question_page}>Add a new question</a></div>
          <div>
          <form onSubmit={this.handleSubmit}>
          <input type="text" name="edit_question" value={this.state.edit_qid} onChange={this.handleChange}/>
          <input type="submit" value="submit"></input>
          </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminPanelPage;
