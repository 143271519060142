import { createSlice } from "@reduxjs/toolkit";

const authReducer = createSlice({
  name: "auth",
  initialState: {
    authenticating: true,
    authenticated: false,
    userDetails: null,
  },
  reducers: {
    authenticate: (state, action) => {
      state.authenticated = true;
      state.userDetails = action.payload.userDetails;
      state.authenticating = false;
    },
    logout: (state) => {
      state.authenticated = false;
      state.userDetails = null;
      state.authenticating = false;
    },
    authenticating: (state) => {
      state.authenticating = true;
    },
  },
});

export const authActions = authReducer.actions;

export default authReducer.reducer;
