import React, { Component } from "react";
import { Input, message } from "antd";
import Paper from "@mui/material/Paper";
import "./../../css/chat-box.css";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import MarkdownAnswer from "./MarkdownAnswer";

class ChatBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: "",
      messages: [],
      isTyping: false,
      question: "",
    };
  }

  // Simulates an API call
  callApi = async (question) => {
    try {
      let ip = Constants.ip;
      let port = Constants.port.hive;
      let endpoint = "/openai";

      // encode user program to base64
      const encodedUserCode = btoa(this.props.userProgram);

      let obj = {
        // base 64 encoded user code
        question: this.props.questionData.base64Question,
        language: this.props.language,
        userCode: encodedUserCode,
        query: question,
      };

      let res = await Photon.post(ip, port, endpoint, obj);

      return res.data.content;
    } catch (error) {
      console.error("Error: ", error);
      return "Sorry, I am unable to answer your question at the moment.";
    }
  };

  // Handles user input submission
  handleSubmit = async () => {
    const userMessage = this.state.input.trim();
    if (!userMessage) return;

    // Add user message to the chat
    this.setState((prevState) => ({
      messages: [...prevState.messages, { user: "User", text: userMessage }],
      input: "",
      isTyping: true,
    }));

    // Simulate API call and response
    const response = await this.callApi(userMessage);

    // Add AI response to the chat
    this.setState((prevState) => ({
      messages: [...prevState.messages, { user: "AI", text: response }],
      isTyping: false,
    }));
  };

  // Handles Enter key press
  keyPress = (e) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };

  // Automatically scroll to the bottom of the chat
  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    return (
      <Paper
        elevation={3}
        sx={{
          p: 0,
          width: "24%",
          height: "100%",
          flex: 1,
          display: "flex",
          border: "1px solid #ccc",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: 1,
            overflowY: "auto",
            padding: "10px",
            backgroundColor: "#f9f9f9",
            borderRadius: "5px 5px 0 0",
          }}
          ref={(el) => (this.chatContainer = el)} // Reference to the chat container
        >
          {
            // Display a welcome message when the chat box is first rendered
            this.state.messages.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  textAlign: "center",
                  color: "#666",
                }}
              >
                <h4>Hi! I'm your AI coding assistant. How can I help you?</h4>
              </div>
            )
          }
          {this.state.messages.map((item, index) => (
            <div
              key={index}
              style={{
                textAlign: item.user === "User" ? "right" : "left",
                margin: "10px 0",
              }}
            >
              <MarkdownAnswer text={item.text} user={item.user} />
            </div>
          ))}
          {this.state.isTyping && (
            <div style={{ textAlign: "left", margin: "10px 0" }}>
              <div className="dots-animation">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
            </div>
          )}
          {/* <div ref={(el) => (this.messagesEnd = el)} /> */}
        </div>

        <div style={{ borderTop: "1px solid #ccc", padding: "10px" }}>
          <Input
            type="text"
            placeholder="Ask AI Chat Bot..."
            value={this.state.input}
            onChange={(e) => this.setState({ input: e.target.value })}
            onKeyDown={this.keyPress}
          />
        </div>
      </Paper>
    );
  }
}

export default ChatBox;
