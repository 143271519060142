import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import FullScreenLoader from "./Loaders/FullScreenLoader";

export const ProtectedRoute = () => {
  const authState = useSelector((state) => state.auth);

  // Render conditionally based on authentication status
  if (authState.authenticating) {
    // Waiting for authentication status, you can render a loading indicator here
    return <FullScreenLoader />;
  } else if (authState.authenticated) {
    // If authorized, return an outlet that will render child elements
    return <Outlet />;
  } else {
    // If not authorized, return an element that will navigate to the login page
    return <Navigate to="/" />;
  }
};
