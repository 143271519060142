import React, { useState, useEffect } from "react";
import Header from "../header";
import QuestionBox from "./question_box";
import EditorBox from "./editor_box";
import ChatBox from "./chat_box";
import "../../css/style.css";
import { useParams, useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

function QuestionPage(props) {
  const [tokenId, setTokenId] = useState(null);
  const [questionData, setQuestionData] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState("motoko");
  const [userProgram, setUserProgram] = useState("");
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    console.log("component mounted : QuestionsPage", props);

    const questionObj = {
      id: id,
      name: "",
      detail: "",
      base64Question: "",
    };

    setQuestionData(questionObj);
    console.log(questionData);
  }, []);

  return (
    <Box
      sx={{
        overflow: "hidden",
        height: "100vh",
        width: "100%",
      }}
    >
      <Header navigate={navigate} />
      <Stack
        direction="row"
        justifyContent={"space-between"}
        sx={{ my: 2, height: "88%", marginBottom: "2rem", gap: ".5rem" }}
      >
        <QuestionBox
          questionData={questionData}
          setQuestionData={setQuestionData}
          navigate={navigate}
        />
        <EditorBox
          questionData={questionData}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          userProgram={userProgram}
          setUserProgram={setUserProgram}
          navigate={navigate}
          questionId={questionData?.id}
        />
        <ChatBox
          questionData={questionData}
          language={selectedLanguage}
          userProgram={userProgram}
        />
      </Stack>
    </Box>
  );
}

export default QuestionPage;
