import React, { useEffect, useState } from "react";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import base64 from "react-native-base64";
import Editor, { useMonaco } from "@monaco-editor/react";

import { Button, Select, Collapse } from "antd";
// import "antd/dist/antd.css";

import { CaretRightOutlined } from "@ant-design/icons";
import { Tabs, Alert, Result, Tag } from "antd";

import Paper from "@mui/material/Paper";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "../../css/style.css";
import RenderCodeOutput from "./RenderCodeOutput";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { configureMonaco } from "../../utils/monacoConfigs";

const { TabPane } = Tabs;

const { Panel } = Collapse;
const { Option } = Select;

const CodeOutputHeadr = ({ executing = false }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        gap: ".5rem",
      }}
    >
      Code output {executing ? <CircularProgress size={15} /> : ""}
    </div>
  );
};

const EditorBox = (props) => {
  const [state, setState] = useState({
    autocomplete: true,
    output: {},
    collapse_output: "0",
    team_members: [],
    friendProgram: "",
    runningTestCases: false,
  });

  const authState = useSelector((state) => state.auth);

  // useEffect(() => {
  //   initialFetchAndSetData();
  // }, []);

  useEffect(() => {
    call_to_fecthCode(null, props.questionData.id);
  }, [props.questionData?.id]);

  // const initialFetchAndSetData = async () => {
  //   const user_dataa = auth.get_user_data();
  //   console.log("user_data inside chatbox=== " + JSON.stringify(user_dataa));
  //   setState((state) => ({
  //     ...state,
  //     tokenId: state.tokenId,
  //     user_data: user_dataa,
  //   }));
  //   // call_to_fecthCode(null, props.questionData.id);
  //   let ip = Constants.ip;
  //   //let ip = "http://localhost:";
  //   let port = Constants.port.hive;
  //   let endpoint = "/get_team_by_id";
  //   let obj = { email: state.user_data?.email };
  //   if (!state.user_data?.email) return;
  //   const team_members = await Photon.post(ip, port, endpoint, obj);
  //   let team_members_data = team_members.data.team_members_data;
  //   team_members_data = await remove_self_user(team_members_data);
  //   setState((state) => ({ ...state, team_members: team_members_data }));
  // };

  // const remove_self_user = (team_members_data) => {
  //   let team = [];
  //   for (let i = 0; i < team_members_data.length; i++) {
  //     if (team_members_data[i].email != state.user_data?.email) {
  //       team.push(team_members_data[i]);
  //     }
  //   }
  //   console.log("team without self" + JSON.stringify(team));
  //   return team;
  // };

  const selectLanguage = (value) => {
    console.log(value);

    props.setSelectLanguage(value);
    call_to_fecthCode(value);
  };

  const call_to_fecthCode = async (language, questionId) => {
    try {
      let obj = {
        user_id: authState.userDetails?.email,
        code_language: language || props.selectedLanguage,
        question_id: questionId || props.questionId,
      };

      if (!obj.question_id) return;
      let ip = Constants.ip;
      //let ip = "http://localhost:";
      let port = Constants.port.hive;
      let endpoint = "/fetchCode";

      let res = await Photon.post(ip, port, endpoint, obj);
      let decodedProgram;
      if ("code" in res.data) {
        decodedProgram = base64.decode(res.data.code);
      } else {
        //move to dashboard
        // return props.navigate("/dashboard");
      }
      //let decodedProgram = base64.decode(res.data.code);
      props.setUserProgram(decodedProgram);
    } catch (error) {
      console.log("Error in fetching code", error);
    }
  };

  const call_to_submit_function = async () => {
    let encodedProgram = base64.encode(props.userProgram);
    let ip = Constants.ip;
    let port = Constants.port.hive;
    let endpoint = "/submit_code";
    let obj = {
      user_id: authState.userDetails?.email,
      code: encodedProgram,
      code_language: props.selectedLanguage,
      question_id: props.questionId,
      type: "submit",
    };
    let res = await Photon.post(ip, port, endpoint, obj);
    setState((state) => ({
      ...state,
      output: res.data.output,
      collapse_output: "1",
    }));
  };

  const call_to_runtest_function = async () => {
    console.log("inside run test");
    setState((state) => ({ ...state, runningTestCases: true }));
    try {
      console.log(props.selectedLanguage);
      let code_language = props.selectedLanguage;

      let encodedProgram = base64.encode(props.userProgram);
      //console.log(encodedProgram);
      let ip = Constants.ip;
      //let ip = "http://localhost:";
      let port = Constants.port.hive;
      let endpoint = "/submit_code";
      let obj = {
        user_id: authState.userDetails?.email,
        code: encodedProgram,
        code_language: code_language,
        question_id: props.questionId,
        type: "run",
      };
      let res = await Photon.post(ip, port, endpoint, obj);
      setState((state) => ({
        ...state,
        output: res.data.output,
        collapse_output: "1",
      }));
      //state.output = res.data.output;
      //let res = res1.json();
    } catch (error) {
      console.log("Error in run test", error);
    } finally {
      setState((state) => ({ ...state, runningTestCases: false }));
    }
  };

  const onChange = (newValue) => {
    props.setUserProgram(newValue);
  };

  const onChange_collapse = () => {
    state.collapse_output === "0"
      ? setState((state) => ({ ...state, collapse_output: "1" }))
      : setState((state) => ({ ...state, collapse_output: "0" }));
  };

  const callback = async (key) => {
    if (key != 1) {
      console.log(key);
      let obj = {
        user_id: key,
        code_language: props.selectedLanguage,
        question_id: props.questionId,
      };
      let ip = Constants.ip;
      //let ip = "http://localhost:";
      let port = Constants.port.hive;
      let endpoint = "/fetchCode";

      let res = await Photon.post(ip, port, endpoint, obj);
      //console.log(JSON.stringify(res));
      let decodedProgram = base64.decode(res.data.code);
      setState((state) => ({
        ...state,
        friendProgram: decodedProgram,
      }));
    }
  };

  return (
    // <div className="EditorBoxContainer">
    //   <div className="EditorBoxCss">
    <Paper
      elevation={3}
      sx={{
        p: 2,
        pt: 0,
        width: "53%",
        maxHeight: "100%",
        overflowY: "hidden",
      }}
    >
      {/* <h1>Editor Box</h1> */}
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane
          tab="Me"
          key="1"
          style={{
            height: "100%",
            paddingY: "1rem",
          }}
        >
          <div className="editor-header-menu-container">
            <Select
              defaultValue={props.selectedLanguage}
              onChange={selectLanguage}
              className="mr-1"
              size="small"
            >
              <Option value="motoko">Motoko</Option>
              {/* <Option value="cpp">C/C++</Option>
                <Option value="python">Python</Option>
                <Option value="java">Java</Option> */}
            </Select>
            <Button
              className="mr-1 icon-button"
              type="primary"
              onClick={call_to_runtest_function}
              ghost
              size="small"
              style={{
                display: "flex",
                justifyContent: state.runningTestCases
                  ? "center"
                  : "flex-start",
                minWidth: "3rem",
              }}
              disabled={state.runningTestCases}
            >
              {state.runningTestCases ? (
                <CircularProgress size={15} />
              ) : (
                <>
                  <PlayArrowIcon
                    style={{
                      fontSize: "1.2rem",
                      marginRight: ".1rem",
                    }}
                  />
                  Run
                </>
              )}
            </Button>
            {/* <Button
                className="submit-button icon-button"
                type="primary"
                onClick={call_to_submit_function}
                size="small"
              >
                <CloudUploadIcon
                  style={{
                    fontSize: "1.2rem",
                    marginRight: ".3rem",
                  }}
                />
                Submit
              </Button> */}
          </div>
          <Editor
            language={props.selectedLanguage}
            defaultLanguage="motoko"
            value={props.userProgram}
            onChange={onChange}
            height="500px"
            width="100%"
            beforeMount={configureMonaco}
            options={{
              minimap: { enabled: false },
              wordWrap: "on",
              wrappingIndent: "indent",
              scrollBeyondLastLine: false,
              fontSize: 14,
              tabSize: 2,
              fixedOverflowWidgets: true,
            }}
          />
          <Collapse
            bordered={false}
            activeKey={state.collapse_output}
            onChange={onChange_collapse}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="site-collapse-custom-collapse"
          >
            <Panel
              header={<CodeOutputHeadr executing={state.runningTestCases} />}
              key="1"
              className="site-collapse-custom-panel"
              style={{
                height: "20vh",
                overflowY: "auto",
              }}
            >
              <RenderCodeOutput
                runningTestCases={state.runningTestCases}
                output={state.output}
              />
            </Panel>
          </Collapse>
        </TabPane>

        {state.team_members.map((item, i) => {
          return (
            <TabPane tab={item.name} key={item?.email}>
              <Editor
                language={props.selectedLanguage}
                value={props.userProgram}
                onChange={onChange}
                height="500px"
                width="100%"
              />
            </TabPane>
          );
        })}
      </Tabs>
    </Paper>
  );
};

export default EditorBox;
