import React, { Component } from "react";
import Header from "../header";
import ListQuestions from "./listquestions";

export class DashboardPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Header history={this.props.history} />
        <ListQuestions history={this.props.history} />
      </div>
    );
  }
}

export default DashboardPage;
