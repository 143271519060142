import React, { useEffect } from "react";
import { Tabs, Alert, Result, Tag } from "antd";

import "../../css/style.css";
import { LinearProgress } from "@mui/material";

const RenderCodeOutput = ({ output, runningTestCases }) => {
  const [allTestCasesPassed, setAllTestCasesPassed] = React.useState(false);
  useEffect(() => {
    // check if any of the test cases failed
    if (
      output.testcaseResults &&
      output.testcaseResults.length > 0 &&
      output.testcaseResults.every((result) => result === 1)
    ) {
      setAllTestCasesPassed(true);
    } else {
      setAllTestCasesPassed(false);
    }
  }, [output]);

  console.log("Code output: ", output);

  if (runningTestCases) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "5rem",
          width: "100%",
        }}
      >
        <LinearProgress
          style={{
            width: "80%",
          }}
        />
      </div>
    );
  }

  switch (output.execution_status) {
    case "success":
      return (
        <div className="accepted">
          {allTestCasesPassed ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <h4
                style={{
                  color: "green",
                  marginBottom: ".5rem",
                }}
              >
                Accepted
              </h4>
              {!!output.stderr && (
                <div className="error">
                  <Alert
                    message="Warning"
                    description={output.stderr}
                    type="warning"
                    showIcon
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {output.testcaseResults.map((result, index) => (
                  <div key={index}>
                    <p>
                      {result === 1 ? (
                        <Tag color="green">Test case {index + 1}</Tag>
                      ) : (
                        <Tag color="red">Test case {index + 1}</Tag>
                      )}
                    </p>
                  </div>
                ))}
              </div>
              {!!output.stderr && (
                <div className="error">
                  <Alert
                    message="Run Time Error"
                    description={output.stderr}
                    type="error"
                    showIcon
                  />
                  {/* <p className="display-linebreak">{output.stderr}</p> */}
                </div>
              )}
            </div>
          )}
        </div>
      );

    case "error":
    case "fail":
      return (
        <div className="error">
          <Alert
            message="Run Time Error"
            description=""
            type="error"
            showIcon
          />
          <p className="display-linebreak error-text">{output.stderr}</p>
        </div>
      );
    case "failed":
      return (
        <div className="failed">
          <Alert
            message="Test Case Failed"
            description=""
            type="warning"
            showIcon
          />
          <div className="testcasefailedbody">
            <p>
              Your Input: <Tag color="blue">{output.test_case}</Tag>
            </p>
            <p>
              Expected Output: <Tag color="blue">{output.expected_out}</Tag>
            </p>

            <p>
              STDOUT:
              <Tag color="blue">{output.stdout}</Tag>
            </p>

            <p>
              Your Output: <Tag color="blue">{output.your_output}</Tag>
            </p>
            <p>
              Test Cases Passed: <Tag color="blue">{output.passed}</Tag>
            </p>
            <p>
              Total Test Cases: <Tag color="blue">{output.total}</Tag>
            </p>
          </div>
        </div>
      );
    default:
      return <div></div>;
  }
};

export default RenderCodeOutput;
