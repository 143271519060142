import React, { useEffect } from "react";
import LandingPage from "./components/LandingPage/landingpage";
import { DashboardPage } from "./components/Dashboard/dashboard";
import { ProtectedRoute } from "./components/protectedroute";
import QuestionPage from "./components/Questions/questions";
// import TeamsPage from "./components/Teams/teams";
import AdminPage from "./components/Admin/admin";
import { AdminPanelPage } from "./components/Admin/adminpanel";
import ProfilePage from "./components/Profile/profile";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageNotFound from "./components/NotFound";
import { validateJWTToken } from "./utils/auth";
import { useDispatch } from "react-redux";
import { authActions } from "./store/features/authSlice";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    checkUserAuthentication();
  }, []);

  const checkUserAuthentication = async () => {
    try {
      const result = await validateJWTToken();

      if (result) {
        dispatch(authActions.authenticating());
        dispatch(
          authActions.authenticate({
            userDetails: {
              email: result.email,
              name: result.name,
            },
          })
        );
      } else {
        dispatch(authActions.logout());
      }
    } catch (error) {
      console.log("Error while validating token", error);
      dispatch(authActions.logout());
    }
  };

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />

          <Route path="/dashboard" element={<ProtectedRoute />}>
            <Route index element={<DashboardPage />} />
          </Route>
          {/* 
          <Route path="/teams" element={<ProtectedRoute />}>
            <Route index element={<TeamsPage />} />
          </Route> */}

          <Route path="/adminpanel" element={<ProtectedRoute />}>
            <Route index element={<AdminPanelPage />} />
          </Route>

          <Route path="/admin/:id" element={<ProtectedRoute />}>
            <Route index element={<AdminPage />} />
          </Route>

          <Route path="/questions/:id/" element={<ProtectedRoute />}>
            <Route index element={<QuestionPage />} />
          </Route>

          <Route path="/profile" element={<ProtectedRoute />}>
            <Route index element={<ProfilePage />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
