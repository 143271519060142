import React, { Component } from "react";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import base64 from "react-native-base64";
import ReactMarkdown from "react-markdown";
import { Tabs } from "antd";
// import "antd/dist/antd.css";

import CodeBlock from "./CodeBlock";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-eclipse";
import { Checkbox, Modal, Button, Select, Collapse } from "antd";

const { TabPane } = Tabs;
const { Option } = Select;

export class QuestionBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenId: null,
      payload: {},
      editor_language: "python",
      question_id: "",
      question_name: "",
      language: "python",
      userProgram: { python: "", java: "", cpp: "", motoko: "" },
      driver_code: { python: "", java: "", cpp: "", motoko: "" },
      question: "",
      solution: "",
      difficulty: "medium",
      difficultylevel: "5",
      tags_static: [
        "strings",
        "array",
        "hashmap",
        "tree",
        "dynamic programming",
        "LinkedList",
      ],
      tags: [],
      companytag_static: ["apple", "google", "facebook", "microsoft", "amazon"],
      companytag: [],
    };
    this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
  }
  async componentDidMount() {
    console.log("component mounted : QuestionsBox", this.props);
  }

  async componentWillReceiveProps(newProps) {
    console.log(
      "This is id question == " + JSON.stringify(newProps.question_data)
    );
    if (newProps.question_data.id != 0) {
      this.fetch_question_payload_fnx(newProps.question_data.id);
    }
  }

  set_values_to_default = async () => {
    console.log("call o set_value_to_default");
    this.setState({
      payload: {},
      editor_language: "python",
      question_id: "",
      question_name: "",
      language: "python",
      userProgram: { python: "", java: "", cpp: "", motoko: "" },
      driver_code: { python: "", java: "", cpp: "", motoko: "" },
      question: "",
      solution: "",
      difficulty: "medium",
      difficultylevel: "5",
      tags: [],
      companytag: [],
    });
  };

  fetch_question_payload_fnx = async (id) => {
    //Photon api call goes here...
    console.log("call to fetch code");
    let ip = Constants.ip;
    //let ip = "http://localhost:";
    let port = Constants.port.hive;
    let endpoint = "/fetch_question_payload_by_id";
    let obj = { id: id };

    let res = await Photon.post(ip, port, endpoint, obj);
    console.log("res obj ==== " + JSON.stringify(res));
    this.setState({ question_id: res.data.question_id });
    this.setState({ question_name: res.data.question_name });
    let question_base64 = base64.decode(res.data.question_description);
    this.setState({ question: question_base64 });
    let solution_base64 = base64.decode(res.data.question_solution);
    this.setState({ solution: solution_base64 });
    let python = base64.decode(res.data.code_base64.python);
    let java = base64.decode(res.data.code_base64.java);
    let cpp = base64.decode(res.data.code_base64.cpp);
    let motoko = base64.decode(res.data.code_base64.motoko);

    let code = { python: python, java: java, cpp: cpp, motoko: motoko };

    this.setState({ userProgram: code });

    let python_driver = base64.decode(res.data.driver_code.python);
    let java_driver = base64.decode(res.data.driver_code.java);
    let cpp_driver = base64.decode(res.data.driver_code.cpp);
    let motokoDriver = base64.decode(res.data.driver_code.motoko);

    let driver_code = {
      python: python_driver,
      java: java_driver,
      cpp: cpp_driver,
      motoko: motokoDriver,
    };

    this.setState({ driver_code: driver_code });
    this.setState({ difficulty: res.data.difficulty });
    this.setState({ difficultylevel: res.data.difficultylevel });
    this.setState({ tags: res.data.tags });
    this.setState({ companytag: res.data.companytag });
    console.log(res.data.tags);
    console.log(this.state.tags);
  };

  handleChangeQuestion = (event) => {
    //console.log("handleEditorChange", event.target.value);

    this.setState({ question: event.target.value });
  };

  handleChangeSolution = (event) => {
    //console.log("handleEditorChange", event.target.value);
    this.setState({ solution: event.target.value });
  };

  handleChangeQuestion_name = (event) => {
    this.setState({ question_name: event.target.value });
    //this.setState({ question_name: event.target.value });
  };

  call_to_model = (title, content) => {
    Modal.error({
      title: title,
      content: content,
    });
  };

  call_to_model_success = (title, content) => {
    Modal.success({
      title: title,
      content: content,
    });
  };

  check_if_any_field_empty = () => {
    if (this.state.question.length < 30) {
      let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
      let content = "Question should have more than 30 characters";
      this.call_to_model(title, content);
      return true;
    }
    if (this.state.solution.length < 30) {
      let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
      let content = "Solution should have more than 30 characters";
      this.call_to_model(title, content);
      return true;
    }
    if (this.state.userProgram.python.length < 5) {
      let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
      let content = "Python program should have more than 5 characters";
      this.call_to_model(title, content);
      return true;
    }
    if (this.state.driver_code.python.length < 5) {
      let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
      let content = "Python program should have more than 5 characters";
      this.call_to_model(title, content);
      return true;
    }

    // Commenting for now since we only support python3 as of now.
    // if (this.state.userProgram.java.length < 5) {
    //   let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
    //   let content = "java program should have more than 30 characters";
    //   this.call_to_model(title, content);
    //   return true;
    // }
    // if (this.state.driver_code.java.length < 5) {
    //   let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
    //   let content = "java program should have more than 30 characters";
    //   this.call_to_model(title, content);
    //   return true;
    // }
    // if (this.state.userProgram.cpp.length < 5) {
    //   let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
    //   let content = "cpp program should have more than 30 characters";
    //   this.call_to_model(title, content);
    //   return true;
    // }
    // if (this.state.driver_code.cpp.length < 5) {
    //   let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
    //   let content = "cpp program should have more than 30 characters";
    //   this.call_to_model(title, content);
    //   return true;
    // }

    if (this.state.tags.length < 1) {
      let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
      let content = "please select atleast one tag";
      this.call_to_model(title, content);
      return true;
    }
    if (this.state.companytag.length < 1) {
      let title = "ERROR MESSAGE POST REQUEST NOT COMPLETED";
      let content = "please select atleast one company tag";
      this.call_to_model(title, content);
      return true;
    }
    return false;
  };

  call_to_submit_question_function = async () => {
    if (await this.check_if_any_field_empty()) {
      return;
    }
    let payload;
    let question_base64 = base64.encode(this.state.question);
    let solution_base64 = base64.encode(this.state.solution);
    let python_base64 = base64.encode(this.state.userProgram.python);
    let java_base64 = base64.encode(this.state.userProgram.java);
    let cpp_base64 = base64.encode(this.state.userProgram.cpp);
    let motokoBase64 = base64.encode(this.state.userProgram.motoko);

    let python_base64_driver = base64.encode(this.state.driver_code.python);
    let java_base64_driver = base64.encode(this.state.driver_code.java);
    let cpp_base64_driver = base64.encode(this.state.driver_code.cpp);
    let motokoBase64Driver = base64.encode(this.state.driver_code.motoko);

    payload = {
      question_name: this.state.question_name,
      question_description: question_base64,
      question_solution: solution_base64,
      code_base64: {
        python: python_base64,
        java: java_base64,
        cpp: cpp_base64,
        motoko: motokoBase64,
      },
      driver_code: {
        python: python_base64_driver,
        java: java_base64_driver,
        cpp: cpp_base64_driver,
        motoko: motokoBase64Driver,
      },
      tags: this.state.tags,
      difficulty: this.state.difficulty,
      difficultylevel: this.state.difficultylevel,
      companytag: this.state.companytag,
    };
    let ip = Constants.ip;
    //let ip = "http://localhost:";
    let port = Constants.port.hive;
    let endpoint;
    if (this.state.question_id != 0) {
      payload.question_id = this.state.question_id;
      endpoint = "/update_question_by_id";
    } else {
      endpoint = "/insert_question_to_db";
    }

    let obj = { payload };

    let res = await Photon.post(ip, port, endpoint, obj);
    console.log(JSON.stringify(res));
    if (res.data.status) {
      let title = "SUCCESS";
      let content = res.data.message;
      this.call_to_model_success(title, content);
    } else {
      let title = "FAILED";
      let content = res.data.message;
      this.call_to_model(title, content);
    }

    if (res.data.type == "insert") {
      this.set_values_to_default();
    } else if (res.data.type == "update") {
      this.fetch_question_payload_fnx(this.state.question_id);
    }
  };

  selectLanguage = (value) => {
    console.log(this.state.userProgram);
    console.log("Selected Language: ", value);
    if (value == "cpp") {
      this.setState({ editor_language: "c_cpp" });
    } else if (value == "motoko") {
      this.setState({ editor_language: "python" });
    } else {
      this.setState({ editor_language: value });
    }
    this.setState(
      {
        language: value,
      },
      () => {
        console.log(".then function");
      }
    );
  };

  onChange = (newValue) => {
    this.state.userProgram[this.state.language] = newValue;
    console.log("change", newValue);
  };

  onChange_driver = (newValue) => {
    this.state.driver_code[this.state.language] = newValue;
  };

  handleChangeDifficulty = async (val) => {
    await this.setState({ difficulty: val });
    console.log(this.state.difficulty);
  };
  handleChangeDifficulty_level = async (val) => {
    await this.setState({ difficultylevel: val });
    console.log(this.state.difficultylevel);
  };

  onChangeTags = async (val) => {
    this.setState({ tags: val });
    console.log(this.state.tags);
  };

  onChangeCompanyTag = async (val) => {
    this.setState({ companytag: val });
  };

  render() {
    return (
      <div className="adminpanel">
        <div className="questionBoxContainerAdmin">
          <p>Question Id = {this.state.question_id}</p>
          <input
            type="text"
            style={{ width: "50%" }}
            value={this.state.question_name}
            onChange={this.handleChangeQuestion_name}
          />
          <Select
            defaultValue={this.state.difficulty}
            style={{ width: 120 }}
            value={this.state.difficulty}
            onChange={this.handleChangeDifficulty}
          >
            <Option value="easy">easy</Option>
            <Option value="medium">medium</Option>
            <Option value="hard">hard</Option>
          </Select>
          <Select
            defaultValue={this.state.difficultylevel}
            style={{ width: 120 }}
            value={this.state.difficultylevel}
            onChange={this.handleChangeDifficulty_level}
          >
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
            <Option value="4">4</Option>
            <Option value="5">5</Option>
            <Option value="6">6</Option>
            <Option value="7">7</Option>
            <Option value="8">8</Option>
            <Option value="9">9</Option>
            <Option value="10">10</Option>
          </Select>
          <br />
          <Checkbox.Group
            options={this.state.tags_static}
            value={this.state.tags}
            onChange={this.onChangeTags}
          />
          <br />
          <Checkbox.Group
            options={this.state.companytag_static}
            value={this.state.companytag}
            onChange={this.onChangeCompanyTag}
          />

          <div className="questionBoxCssAdmin">
            <h1>{this.state.question_name}</h1>

            <p></p>
            <Tabs defaultActiveKey="1" onChange={this.callback}>
              <TabPane tab="Question" key="1">
                {/* <ReactMarkdown
                source={this.state.question_data.detail}
                renderers={{
                  code: CodeBlock,
                }}
              /> */}
                <textarea
                  style={{ height: "600px", width: "100%" }}
                  value={this.state.question}
                  onChange={this.handleChangeQuestion}
                />
              </TabPane>
              <TabPane
                tab="Solution"
                key="2"
                disabled={this.state.show_solution}
              >
                {/* <ReactMarkdown
                source={this.state.question_data.detail_solution}
                renderers={{
                  code: CodeBlock,
                }}
              /> */}
                <textarea
                  style={{ height: "600px", width: "100%" }}
                  value={this.state.solution}
                  onChange={this.handleChangeSolution}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>

        <div className="Editor">
          <AceEditor
            value={this.state.userProgram[this.state.language]}
            mode={this.state.editor_language}
            theme="chrome"
            onChange={this.onChange}
            enableLiveAutocompletion={this.state.autocomplete}
          />
          <Select
            defaultValue={this.state.language}
            onChange={this.selectLanguage}
          >
            <Option value="motoko">Motoko</Option>
            <Option value="cpp">C/C++</Option>
            <Option value="python">Python</Option>
            <Option value="java">Java</Option>
          </Select>

          <div className="Editor">
            <AceEditor
              value={this.state.driver_code[this.state.language]}
              mode={this.state.editor_language}
              theme="chrome"
              onChange={this.onChange_driver}
              enableLiveAutocompletion={this.state.autocomplete}
            />
            <Select
              defaultValue={this.state.language}
              onChange={this.selectLanguage}
            >
              <Option value="motoko">Motoko</Option>
              <Option value="cpp">C/C++</Option>
              <Option value="python">Python</Option>
              <Option value="java">Java</Option>
            </Select>
          </div>
          <Button
            className="run-button"
            type="primary"
            onClick={this.call_to_submit_question_function}
          >
            UPDATE / INSERT
          </Button>
        </div>
      </div>
    );
  }
}

export default QuestionBox;
